<template>
  <div id="app">

    <router-view />

    <van-tabbar class="layout-tabbar" route>
      <van-tabbar-item to="/">
        <i slot="icon" class="van-icon-home-o"></i>
        <span class="text">首页</span>
      </van-tabbar-item>
      <van-tabbar-item to="/push">
        <i slot="icon" class="van-icon-pause"></i>
        <span class="text">发布</span>
      </van-tabbar-item>

    </van-tabbar>
    <!-- /标签导航栏 -->

  </div>
</template>

<script>
export default {
  data() {
    return {
      active: 0
    };
  },
};

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  color: #fff;
  background-color: #1989fa;
  padding: 10px;
}


</style>
