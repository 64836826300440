import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant'

/** 引入组件样式 **/
import 'vant/lib/index.css'

/** 引入需要的组件 **/
import { Form } from 'vant';
import { Field } from 'vant';
import { Button } from 'vant';
import { Divider } from 'vant';
import { ImagePreview } from 'vant';
import { NoticeBar } from 'vant';
import { Tabbar, TabbarItem } from 'vant';



Vue.config.productionTip = false

/** 全局注册你需要的组件 **/
Vue.use(Vant)
// 表单
Vue.use(Form);
// 属性
Vue.use(Field);
// 按钮
Vue.use(Button);
// 分割线
Vue.use(Divider);
// 图片预览
Vue.use(ImagePreview);
// 通知栏
Vue.use(NoticeBar);
// 标签栏
Vue.use(Tabbar);
// 标签栏
Vue.use(TabbarItem);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
