<template>
  <div class="home-container">
    <nav>蔚县便民服务站</nav>
    <div style="padding: 100px">
      <img alt="Vue logo" src="../assets/logo.png">
    </div>

  </div>
</template>

<script>
export default {
  name: 'HomePage',
  components: {},
  props: {},
  data() {
    return {}
  },
  computed: {},
  watch: {},
  created() {
  },
  mounted() {
  },
  methods: {}
}
</script>

<style scoped></style>


